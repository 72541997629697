import { useEffect, useState } from "react";
import { map, xeddo } from "../assets/images";
import { hawaiian } from "../assets/logo";
import { FlightCard, PriceDetails, PriceGraph } from "../container";
import { Link, useLocation, useNavigate } from "react-router-dom";
import axios from "axios";
import TripLoader from "./TripLoader";

const FlightChoose = ({ comingTo, headingTo, date }) => {
  const [priceShown, setPriceShow] = useState(true);
  const location = useLocation();
  const navigate = useNavigate();
  const [trips, setTrips] = useState([]);
  const [loading, setLoading] = useState(false);

  const formatDate = (date) => {
    if (!date) return "";
    return new Date(date).toLocaleDateString("en-GB", {
      day: "2-digit",
      month: "2-digit",
      year: "numeric",
    });
  };

  const formattedDate = formatDate(date);

  const getTrips = async () => {
    setLoading(true);
    try {
      const response = await axios.get(
        "https://travelinkserver.vercel.app/api/v1/admin/alltrips"
      );
      const data = response.data;
      if (data) {
        const formatTripDate = (tripdate) => {
          if (!tripdate) return "";
          return new Date(tripdate).toLocaleDateString("en-GB", {
            day: "2-digit",
            month: "2-digit",
            year: "numeric",
          });
        };

        const filteredTrips = data.filter(
          (trip) =>
            trip.departure === comingTo &&
            trip.destination === headingTo &&
            formatTripDate(trip.tripdate) === formattedDate
        );

        const tripsWithVehicleData = await Promise.all(
          filteredTrips.map(async (trip) => {
            const vehicleResponse = await axios.get(
              `https://travelinkserver.vercel.app/api/v1/admin/getvehicle/${trip.vehicleId}`
            );
            const vehicleData = vehicleResponse.data;

            const bookedSeatsResponse = await axios.get(
              `https://travelinkserver.vercel.app/api/v1/user/bookedseats/${vehicleData._id}`
            );
            const bookedSeatsData = bookedSeatsResponse.data;
            const bookedSeats = bookedSeatsData.length;

            return {
              ...trip,
              vehicleSeats: vehicleData.seats,
              vehicleName: vehicleData.name,
              vehiclePlate: vehicleData.plate,
              vehicleid: vehicleData._id,
              bookedSeats: bookedSeats,
            };
          })
        );
        setTrips(tripsWithVehicleData);
        setLoading(false);
      }
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };

  useEffect(() => {
    getTrips();
  }, [comingTo, headingTo]);

  const navigateToVehicle = (trip) => {
    const { vehicleSeats, vehicleName, vehiclePlate } = trip;
    const commonParams = {
      vehicleid: trip.vehicleId,
      vehiclename: vehicleName,
      vehiclereg: vehiclePlate,
      price: trip.price,
      leavingTime: trip.leavingTime,
      departure: trip.departure,
      destination: trip.destination,
      arrivalTime: trip.arrivalTime,
      tripdate: formattedDate,
    };

    
      navigate("/book", { state: commonParams }); 
  };

  return (
    <>
      <div className="flex lg:flex-row flex-col items-start justify-between gap-16 ">
        <div className="w-full lg:w-[872px] h-full flex flex-col gap-5">
          <div className="flex items-start justify-start">
            <h1 className="text-[#6E7491]  text-lg leading-6 font-semibold">
              Choose a <span className="text-[#605DEC]">departing </span>/{" "}
              <span className="text-[#605DEC]">returning </span>Trip
            </h1>
          </div>
          <div className="flex items-center justify-between w-full">
            <div>
              <p className="text-black font-semibold text-2xl tracking-wider">{comingTo}</p>
            </div>
            <div>
              <img src="../../images/forward.png" className="w-8 h-8 object-fit" alt="" />
            </div>
            <div>
              <p className="font-semibold text-black text-2xl tracking-wider">{headingTo}</p>
            </div>
          </div>
          <div>
            {loading ? (
              <div>
                <TripLoader />
              </div>
            ) : (
              <div>
                {trips.map((trip) => {
                  return (
                    <div className="w-full flex flex-col items-start justify-start  border-[1px] border-[#E9E8FC] rounded-xl">
                      <div
                        className="w-full cursor-pointer border-b-[1px] border-[#E9E8FC] hover:bg-[#F6F6FE] transition-all duration-300 focus:bg-[#F6F6FE]"
                        // onClick={() => setPriceShow(false)}
                        onClick={() => navigateToVehicle(trip)}
                      >
                        <FlightCard
                          img={hawaiian}
                          duration="16h 45m"
                          name={headingTo}
                          time={(trip.leavingTime, "-", trip.arrivalTime)}
                          stop={trip.vehicleSeats}
                          leaves={trip.leavingTime}
                          arrives={trip.arrivalTime}
                          hnl="2h 45m in HNL"
                          price={trip.price}
                          trip="round trip"
                        />
                      </div>
                    </div>
                  );
                })}
              </div>
            )}
          </div>

          <div className="w-full lg:mt-12">
            <img src={xeddo} alt="map" className="w-full h-full object-cover" />
          </div>
        </div>

        {priceShown && <PriceGraph />}

        {!priceShown && (
          <div className="mt-10 flex flex-col gap-10 justify-end items-start lg:items-end">
            <PriceDetails />
            <Link to="/passenger-info" className="mt-5">
              <button className="text-[#605DEC] border-2 border-[#605DEC] py-2 px-3 rounded hover:bg-[#605DEC] hover:text-white transition-all duration-200">
                Save & Close
              </button>
            </Link>
          </div>
        )}
      </div>
    </>
  );
};

export default FlightChoose;
