import React from "react";
import { xeddo } from "../assets/images";

const Profile = () => {
  const email = localStorage.getItem("email");

  const handleLogout = () => {
    localStorage.removeItem("token");
    localStorage.removeItem("email");
    localStorage.removeItem("Authenticated");
    window.location.reload();
  };
  return (
    <div>
      <div class="flex justify-center mt-20 px-8">
        <form class="max-w-2xl">
          <div class="flex flex-wrap border shadow rounded-lg p-3 dark:bg-gray-600">
            <div className="flex justify-center items-center w-full">
              <img src={xeddo} className="h-32 w-32 rounded-full" alt="" />
            </div>
            <h2 class="text-xl font-bold text-black pb-2">Account settings:</h2>

            <div class="flex flex-col gap-2 w-full border-gray-400">
              <div>
                <label class="text-gray-600 dark:text-gray-400">
                  User name
                </label>
                <input
                  class="w-full py-3 border border-slate-200 rounded-lg px-3 focus:outline-none focus:border-slate-500 hover:shadow dark:bg-gray-600 dark:text-gray-100"
                  type="text"
                />
              </div>

              <div>
                <label class="text-gray-600 dark:text-gray-400">Email</label>
                <input
                  value={email}
                  class="w-full py-3 border border-slate-200 rounded-lg px-3 focus:outline-none focus:border-slate-500 hover:shadow dark:bg-gray-600 dark:text-gray-100"
                  type="text"
                />
              </div>

              <div>
                <label class="text-gray-600 dark:text-gray-400">Bio</label>
                <textarea
                  class="w-full py-3 border border-slate-200 rounded-lg px-3 focus:outline-none focus:border-slate-500 hover:shadow dark:bg-gray-600 dark:text-gray-100"
                  name="bio"
                ></textarea>
              </div>
              <div class="flex justify-between">
                <button
                  class="py-1.5 px-3 m-1 text-center bg-red-700 border rounded-md text-white  hover:bg-violet-500 hover:text-gray-100 dark:text-gray-200 dark:bg-violet-700"
                  onClick={handleLogout}
                >
                  Logout
                </button>
                <button
                  class="py-1.5 px-3 m-1 text-center bg-violet-500 border rounded-md text-white  hover:bg-violet-500 hover:text-gray-100 dark:text-gray-200 dark:bg-violet-700"
                  type="submit"
                >
                  Save changes
                </button>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};

export default Profile;
