import React, { useEffect, useState } from "react";
import axios from "axios";
import { useLocation, useNavigate } from "react-router-dom";
import Loader from "../components/Loader";
import Navbar from "../components/Navbar";
import * as Icon from "react-feather";

const BookingPage = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { search, comingTo, headingTo, date } = location.state || {};
  const [trips, setTrips] = useState([]);
  const [loading, setLoading] = useState(false);

  const formatDate = (date) => {
    if (!date) return "";
    return new Date(date).toLocaleDateString("en-GB", {
      day: "2-digit",
      month: "2-digit",
      year: "numeric",
    });
  };

  const formattedDate = formatDate(date);

  const getTrips = async () => {
    setLoading(true);
    try {
      const response = await axios.get(
        "https://travelinkserver.vercel.app/api/v1/admin/alltrips"
      );
      const data = response.data;
      if (data) {
        const formatTripDate = (tripdate) => {
          if (!tripdate) return "";
          return new Date(tripdate).toLocaleDateString("en-GB", {
            day: "2-digit",
            month: "2-digit",
            year: "numeric",
          });
        };

        const filteredTrips = data.filter(
          (trip) =>
            trip.departure === comingTo &&
            trip.destination === headingTo &&
            formatTripDate(trip.tripdate) === formattedDate
        );

        const tripsWithVehicleData = await Promise.all(
          filteredTrips.map(async (trip) => {
            const vehicleResponse = await axios.get(
              `https://travelinkserver.vercel.app/api/v1/admin/getvehicle/${trip.vehicleId}`
            );
            const vehicleData = vehicleResponse.data;

            const bookedSeatsResponse = await axios.get(
              `https://travelinkserver.vercel.app/api/v1/user/bookedseats/${vehicleData._id}`
            );
            const bookedSeatsData = bookedSeatsResponse.data;
            const bookedSeats = bookedSeatsData.length;

            return {
              ...trip,
              vehicleSeats: vehicleData.seats,
              vehicleName: vehicleData.name,
              vehiclePlate: vehicleData.plate,
              vehicleid: vehicleData._id,
              bookedSeats: bookedSeats,
            };
          })
        );
        setTrips(tripsWithVehicleData);
        setLoading(false);
      }
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };

  useEffect(() => {
    getTrips();
  }, [comingTo, headingTo]);

  const navigateToVehicle = (trip) => {
    const { vehicleSeats, vehicleName, vehiclePlate } = trip;
    const commonParams = {
      vehicleid: trip.vehicleId,
      vehiclename: vehicleName,
      vehiclereg: vehiclePlate,
      price: trip.price,
      leavingTime: trip.leavingTime,
      departure: trip.departure,
      destination: trip.destination,
      arrivalTime: trip.arrivalTime,
      tripdate: formattedDate,
    };

    if (vehicleSeats > 5) {
      navigate("/sevenseater", { state: commonParams });
    } else {
      navigate("/fiveseater", { state: commonParams });
    }
  };

  return (
    <div className="h-screen flex-1 w-full">
      {loading ? (
        <div className="flex h-screen w-full justify-center items-center">
          <Loader />
        </div>
      ) : (
        <div>
          <div className="flex sm:px-16 px-6 justify-center items-center bg-blue-500">
            <div className="w-full xl:max-w-[1280px]">
              <Navbar />
            </div>
          </div>

          <div className="bg-gray-200 w-full h-screen sm:px-32">
            {/* trip details */}
            <div className="w-full sm:pt-10 pt-6 px-4">
              <div className="w-full h-16 rounded-xl flex justify-between items-center sm:px-4">
                <div className="">
                  <button
                    onClick={() => navigate(-1)}
                    className="justify-center items-center flex bg-black h-10 w-10 rounded-full items-center h-12 w-12"
                  >
                    <Icon.ChevronLeft size={20} color="white" />
                  </button>
                </div>
                <div>
                  <p className="font-poppins text-black text-2xl">{comingTo}</p>
                </div>
                <div>
                  <Icon.ArrowRight size={23} color="blue" />
                </div>
                <div>
                  <p className="font-poppins text-black text-2xl">
                    {headingTo}
                  </p>
                </div>
                <div className="hidden md:block">
                  <p className="font-poppins hidden md:block text-slate-500 text-2xl">
                    {date}
                  </p>
                </div>
              </div>
            </div>

            {loading ? (
              <div className="flex-1 justify-center items-center">
                <div className="loader"></div>
              </div>
            ) : (
              <div className="overflow-y-auto sm:my-16 py-6">
                {trips.length === 0 ? (
                  <div className="w-full p-5 justify-center items-center bg-slate-300 rounded-2xl">
                    <div className="flex flex-col justify-center items-center text-center space-y-4">
                      <img
                        src="../images/search.png"
                        className="h-32 w-32"
                        alt=""
                      />
                      <p className="text-slate-500">
                        No trips found for the selected criteria.
                      </p>
                    </div>
                  </div>
                ) : (
                  trips.map((trip) => (
                    <div className="px-5" key={trip._id}>
                      <div className="mt-3">
                        <span className="text-slate-500 text-xl">
                          <span className="text-black font-bold">Date:</span>{" "}
                          {formattedDate}
                        </span>
                      </div>

                      <div className="my-4 space-y-2 py-5 w-full border border-slate-300 bg-white rounded-xl items-center justify-center">
                        <div className="flex w-full justify-between items-center px-5">
                          
                          <div>
                            <span className="text-slate-700 md:text-lg text-sm">
                              Type:{" "}
                              {trip.vehicleSeats <= 5 ? (
                                <span className="text-blue-500">5 seater</span>
                              ) : (
                                <span className="text-blue-500">7 seater</span>
                              )}
                            </span>
                          </div>
                        </div>
                        <div className="flex justify-between items-center px-4 w-full">
                          <div>
                            <span className="font-bold text-slate-500 md:text-xl text-sm">
                              Departure
                            </span>
                            <span className="font-bold text-black md:text-xl text-sm">
                              {trip.leavingTime}
                            </span>
                          </div>
                          {/* <span className="text-slate-700 text-xl font-semibold">
                              <span className="text-black font-bold">
                                Vehicle:
                              </span>{" "}
                              {trip.vehicleName}
                            </span> */}
                          <div>
                            <span className="font-bold text-slate-500 md:text-xl text-sm">
                              Arrival
                            </span>
                            <span className="font-bold text-black md:text-xl text-sm">
                              {trip.arrivalTime}
                            </span>
                          </div>
                        </div>
                        <div className="flex w-full justify-between items-center px-4">
                          <div>
                            <span className="text-slate-500 md:text-lg text-sm">
                              Kshs.{trip.price}
                            </span>
                            <span className="text-slate-500 md:text-lg text-sm">
                              Available seats:{" "}
                              {trip.vehicleSeats <= 5
                                ? 5 - trip.bookedSeats +1
                                : 7 - trip.bookedSeats + 1}
                            </span>
                          </div>
                          <div>
                            <button
                              onClick={() => navigateToVehicle(trip)}
                              className="sm:p-2 md:px-5 px-3 justify-center items-center rounded-md bg-blue-500"
                            >
                              <span className="text-white md:text-lg text-sm">View</span>
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  ))
                )}
              </div>
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default BookingPage;
