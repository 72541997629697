import { Route, Routes } from "react-router-dom";
import "./App.css";
import HomePage from "./pages/HomePage";
import LoginPage from "./pages/LoginPage";
import BookingPage from "./pages/BookingPage";
import ExplorePage from "./pages/ExplorePage";
import BookPage from "./pages/BookPage";
import PrivateRoute from "./context/PrivateRoute";
import ProfilePage from "./pages/ProfilePage";

function App() {
  return (
    <Routes>
      <Route index element={<HomePage />} />
      <Route path="/login" element={<LoginPage />} />
      {/* Private routes */}
      <Route element={<PrivateRoute />}>
        <Route path="/booking" element={<BookingPage />} />
        <Route path="/explore" element={<ExplorePage />} />
        <Route path="/book" element={<BookPage />} />
        <Route path="/profile" element={<ProfilePage />} />
      </Route>
    </Routes>
  );
}

export default App;
