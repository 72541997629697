import React, { useState } from "react";
import { xeddo } from "../assets/images";
import axios from "axios";
import { Link, useNavigate } from "react-router-dom";
import AuthLoader from "./AuthLoader";

const Login = () => {
  const [email, setEmail] = useState("jey@gmail.com");
  const [password, setPassword] = useState("123456");
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const [error,setError] = useState(false);

  //attempt login
  const handleLogin = async (e) => {
    e.preventDefault();
    setLoading(true);
    // console.log(email, password);
    try {
      const response = await axios.post(
        "https://lipabooking.vercel.app/api/v1/user/login",
        { email, password }
      );
      const success = response.data;
      console.log(success)
      // localStorage.setItem("user", response.data.admin.fullname);
      // localStorage.setItem("_uid", response.data.admin._id);
      localStorage.setItem("email", response.data.userdata.email);
      localStorage.setItem("token", response.data.token);
      localStorage.setItem("Authenticated", true);
      if (success) {
        console.log("user logged in successfully");
        navigate("/");
        console.log(success);
      }
    } catch (error) {
      console.log(error);
      setLoading(false);
      setError(true);
    }
  };
  return (
    <div>
      <div class="bg-gray-100 flex h-screen items-center justify-center px-4 sm:px-6 lg:px-8">
        {loading ? (
          <div className="flex h-screen w-full justify-center items-center">
            <AuthLoader />
          </div>
        ) : (
          <div>
            <div class="w-full max-w-md space-y-8">
              <div class="bg-white shadow-md rounded-md p-6">
                <img
                  class="mx-auto h-full w-full rounded-t-2xl"
                  src={xeddo}
                  alt=""
                />
                <div className="text-center w-full flex justify-center items-center">
                {
                    error && (
                      <div className="flex flex-col text-center flex justify-center items-center rounded-lg items-center bg-red-200 h-10 w-80 my-3 space-y-2">
                        <p className="text-lg">Invalid email or password</p>
                      </div>
                    )

  
                }
                </div>

                <h2 class="my-3 text-center text-3xl font-bold tracking-tight text-gray-900">
                  Login
                </h2>

                <form class="space-y-6" onSubmit={handleLogin}>
                  <div>
                    <label
                      for="password"
                      class="block text-sm font-medium text-gray-700"
                    >
                      Email
                    </label>
                    <div class="mt-1">
                      <input
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        name="email"
                        type="email-address"
                        autocomplete="email-address"
                        required
                        class="px-2 py-3 mt-1 block w-full rounded-md border border-gray-300 shadow-sm focus:border-sky-500 focus:outline-none focus:ring-sky-500 sm:text-sm"
                      />
                    </div>
                  </div>

                  <div>
                    <label
                      for="password"
                      class="block text-sm font-medium text-gray-700"
                    >
                      Password
                    </label>
                    <div class="mt-1">
                      <input
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                        name="password"
                        type="password"
                        autocomplete="password"
                        required
                        class="px-2 py-3 mt-1 block w-full rounded-md border border-gray-300 shadow-sm focus:border-sky-500 focus:outline-none focus:ring-sky-500 sm:text-sm"
                      />
                    </div>
                  </div>

                  <div>
                    <button
                      type="submit"
                      class="flex w-full justify-center rounded-md border border-transparent bg-blue-700 py-3 px-4 text-sm font-medium text-white shadow-sm hover:bg-opacity-75 focus:outline-none focus:ring-2 focus:ring-sky-400 focus:ring-offset-2"
                    >
                      Login
                    </button>
                  </div>

                  <div className="flex w-full justify-between items-center">
                    <div>
                      <Link to="/register">
                      <p className="text-blue-400">Create Account</p>
                      </Link>
                    </div>
                    <div>
                    <Link to="/forgot">
                      <p className="text-blue-400">Forgot Password</p>
                      </Link>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default Login;
